<template>
  <v-dialog v-model="dialog" persistent max-width="350px">
    <v-card>
      <v-card-title class="headline">
        Change {{ pluralString(user.first_name) }} Status
      </v-card-title>
      <v-card-text>
        <v-alert v-model="error" type="error"
          >An error has occurred when attempting to complete this task. If this
          problem persists, please contact support</v-alert
        >
        <p>
          You are about to set {{ user.full_name }}'s status to
          <strong>{{ status }}</strong>
        </p>
        <p class="mb-0">Are you sure you wish to do this?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="cancelUpdate()"
          >No, cancel</v-btn
        >
        <v-btn
          color="success"
          depressed
          @click="updateConfirmed()"
          :loading="loading"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      user: {},
      statusId: null,
      status: "",
      loading: false,
      error: false,
    };
  },
  methods: {
    updateUserStatus: function(user, statusId) {
      this.dialog = true;
      this.user = user;
      this.statusId = statusId;
      this.status =
        statusId === 1
          ? "Active"
          : statusId === 2
          ? "Archived"
          : statusId === 3
          ? "Suspended"
          : "";
    },
    cancelUpdate: function() {
      this.dialog = false;
      this.user = {};
      this.statusId = null;
      this.status = "";
    },
    updateConfirmed: function() {
      const appId = this.$route.params.id;
      this.loading = true;
      this.error = false;

      this.$store
        .dispatch("users/updateStatus", {
          appId,
          id: this.user.id,
          status: this.statusId,
        })
        .then(() => {
          this.loading = false;
          this.dialog = false;
          this.user = {};
          this.statusId = null;
          this.status = "";
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
};
</script>
