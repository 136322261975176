<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Users</h1>
        </v-col>
        <v-col class="pl-12">
          <div class="input-container d-flex align-center">
            <v-text-field
              label="Search Users"
              outlined
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
              v-model="searchTerm"
              clearable
            ></v-text-field>
            <v-menu
              offset-y
              left
              transition="slide-y-transition"
              :close-on-click="tableFilters.menu.closeOnClick"
              :close-on-content-click="tableFilters.menu.closeOnContentClick"
            >
              <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                      icon
                      v-on="{ ...onTooltip, ...onMenu }"
                      v-bind="attrsMenu"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </template>
                  <span>Filters</span>
                </v-tooltip>
              </template>
              <v-card elevation="0" color="grey lighten-5">
                <v-card-subtitle class="d-flex align-center">
                  <div class="font-weight-bold">Filters</div>
                  <v-spacer></v-spacer>
                  <v-btn outlined x-small>Reset</v-btn>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                  <v-select
                    label="Filter By"
                    outlined
                    hide-details
                    dense
                    width="150"
                    :items="tableFilters.options.items"
                    item-text="label"
                    item-value="value"
                    v-model="tableFilters.options.selected"
                    style="width: 200px"
                    background-color="white"
                  ></v-select>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="addUserForm.dialog = true"
                  v-on="on"
                >
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New User</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="warning"
                  v-on="on"
                  :to="{ name: 'app-users-roles-permissions' }"
                >
                  <v-icon>mdi-key</v-icon>
                </v-btn>
              </template>
              <span>Roles & Permissions</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!users.length">
          <v-alert type="warning" prominent>No Users could be found</v-alert>
        </v-col>
        <v-col v-for="user in users" v-bind:key="user.id" cols="4">
          <v-card :loading="userLoading === user.id">
            <v-list-item
              :to="{ name: 'app-users-profile', params: { userId: user.id } }"
            >
              <v-list-item-avatar
                :color="user.status === 1 ? 'accent' : 'grey'"
              >
                <img
                  v-if="user.avatar"
                  :src="getAvatar(user.avatar)"
                  :alt="user.full_name"
                />
                <span v-else class="white--text">{{ user.initials }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user.full_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-card-actions>
              <v-menu offset-y>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        x-small
                        depressed
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                      >
                        <v-icon small>mdi-account-cog</v-icon>
                        <v-icon small>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <span>User Actions</span>
                  </v-tooltip>
                </template>
                <v-list dense>
                  <v-list-item
                    :to="{
                      name: 'app-users-profile',
                      params: { userId: user.id },
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>View Profile</v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="beginEditingUser(user)"
                    v-if="user.status === 0"
                  >
                    <v-list-item-icon>
                      <v-icon color="info">mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Edit User</v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="beginEditingUserRolePermission(user)"
                    v-else-if="hasPermission('edit-user-roles')"
                  >
                    <v-list-item-icon>
                      <v-icon color="warning">mdi-key</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content
                      >Edit Roles/Permissions</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item
                    @click="resendSetupEmail(user.id)"
                    v-if="user.status === 0"
                  >
                    <v-list-item-icon>
                      <v-icon color="warning">mdi-email-send</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content
                      >Resend Setup Email</v-list-item-content
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    :color="getUserStatusColor(user)"
                    v-on="on"
                  >
                    {{ getUserStatusLabel(user) }}
                    <v-icon x-small right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    key="active"
                    :disabled="user.pivot.status === 1"
                    @click="updateUserStatus(user, 1)"
                  >
                    <v-list-item-title>Active</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    key="archived"
                    :disabled="user.pivot.status === 2"
                    @click="updateUserStatus(user, 2)"
                  >
                    <v-list-item-title>Archived</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    key="suspended"
                    :disabled="user.pivot.status === 3"
                    @click="updateUserStatus(user, 3)"
                  >
                    <v-list-item-title>Suspended</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="addUserForm.dialog" persistent max-width="600px">
      <v-card>
        <v-form @submit.prevent="addUser" method="post">
          <v-card-title>
            <span class="headline">Add User</span>
            <v-spacer></v-spacer>
            <span class="subtitle-2">How it works?</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-alert type="error" v-if="addUserForm.error">
              Sorry, something went wrong when attempting to create this user.
              If it continues to fail, please contact Vital Hike for help
            </v-alert>
            <v-text-field
              label="Email Address *"
              type="email"
              v-model="addUserForm.email"
              dense
              outlined
              required
              :error="addUserForm.errors.hasOwnProperty('email')"
              :error-messages="addUserForm.errors['email']"
            ></v-text-field>
            <v-select
              :items="roles"
              item-text="name"
              item-value="id"
              label="User Role(s) *"
              v-model="addUserForm.roles"
              dense
              multiple
              outlined
              :error="addUserForm.errors.hasOwnProperty('roles')"
              :error-messages="addUserForm.errors['roles']"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="addUserForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="addUserForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editUserForm.dialog" persistent max-width="600px">
      <v-card>
        <v-form @submit.prevent="editUser" method="post">
          <v-card-title>
            <span class="headline">Edit User</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-alert type="info" dense class="mb-6">
              If you are changing the email address of this user, a new setup
              email will be sent to them
            </v-alert>
            <v-alert type="error" v-if="editUserForm.error">
              Sorry, something went wrong when attempting to edit this user. If
              it continues to fail, please contact Vital Hike for help
            </v-alert>
            <v-text-field
              label="Email Address *"
              type="email"
              v-model="editUserForm.email"
              dense
              outlined
              required
              :error="editUserForm.errors.hasOwnProperty('email')"
              :error-messages="editUserForm.errors['email']"
            ></v-text-field>
            <v-select
              :items="roles"
              item-text="name"
              item-value="id"
              label="User Role(s) *"
              v-model="editUserForm.roles"
              dense
              multiple
              outlined
              :error="editUserForm.errors.hasOwnProperty('roles')"
              :error-messages="editUserForm.errors['roles']"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="stopEditingUser()"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="editUserForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editUserRolesPermission.dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-form>
          <v-card-title>
            <span class="headline"
              >Edit {{ pluralString(editUserRolesPermission.firstName) }} Roles
              & Permissions</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-select
              :items="roles"
              item-text="name"
              item-value="id"
              label="User Role(s) *"
              v-model="editUserRolesPermission.roles"
              dense
              multiple
              outlined
              :error="editUserRolesPermission.errors.hasOwnProperty('roles')"
              :error-messages="editUserRolesPermission.errors['roles']"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="stopEditingUserRolePermission()"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="editUserRolesPermission.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <UpdateUserStatusWarning ref="updateUserStatusWarning" />
    <v-snackbar v-model="snackbar.open" :timeout="snackbar.timeout">
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import * as Utils from "@/utils/index.js";
import UpdateUserStatusWarning from "./components/UpdateUserStatusWarning";

export default {
  components: {
    UpdateUserStatusWarning,
  },

  data() {
    return {
      searchTerm: null,
      appId: parseInt(this.$route.params.id),
      tableLoading: false,
      tableHeaders: [
        { text: "Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      tableFilters: {
        menu: {
          open: false,
          closeOnClick: true,
          closeOnContentClick: false,
        },
        options: {
          items: [
            { value: 0, label: "All" },
            { value: 1, label: "Active" },
            { value: 2, label: "Archived" },
            { value: 3, label: "Suspended" },
          ],
          selected: 0,
        },
      },
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+/.test(v) || "Email must be valid",
      ],
      addUserForm: {
        dialog: false,
        email: null,
        roles: null,
        error: false,
        errors: {},
        loading: false,
      },
      editUserForm: {
        dialog: false,
        user: null,
        email: null,
        roles: null,
        error: false,
        errors: {},
        loading: false,
      },
      editUserRolesPermission: {
        dialog: false,
        user: null,
        firstName: null,
        roles: null,
        error: false,
        errors: {},
        loading: false,
      },
      userStatusUpdating: null,
      userStatuses: [
        { value: 1, label: "Active", color: "success" },
        { value: 2, label: "Archived", color: "error" },
        { value: 3, label: "Suspended", color: "error" },
        { value: 4, label: "Active - Setup Required", color: "warning" },
      ],
      userLoading: null,
      snackbar: {
        open: false,
        text: "",
        timeout: 4000,
      },
    };
  },

  computed: {
    users() {
      let users = this.$store.state.users["users"].filter((u) => {
        if (this.tableFilters.options.selected === 0) {
          return u;
        } else if (this.tableFilters.options.selected === 4) {
          return u.status === 0;
        }

        return u.pivot.status === this.tableFilters.options.selected;
      });

      if (
        (this.searchTerm !== "" || this.searchTerm !== null) &&
        typeof this.searchTerm === "string"
      ) {
        users = users.filter((u) => {
          const name = u.full_name.toLowerCase();
          const email = u.email.toLowerCase();
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            email.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return users;
    },
    roles() {
      return this.$store.state.users["appRoles"];
    },
  },

  methods: {
    addUser: function() {
      const email = this.addUserForm.email;
      const roles = this.addUserForm.roles;
      const appId = this.$route.params.id;
      this.addUserForm.loading = true;
      this.addUserForm.error = false;
      this.addUserForm.errors = {};

      this.$store
        .dispatch("users/addUser", { appId, email, roles })
        .then(() => {
          this.addUserForm = {
            dialog: false,
            email: null,
            roles: null,
            error: false,
            errors: {},
            loading: false,
          };
        })
        .catch((err) => {
          this.addUserForm.error = true;
          this.addUserForm.errors = err.response.data.errors;
          this.addUserForm.loading = false;
        });
    },
    beginEditingUser: function(user) {
      if (user.status === 0) {
        this.editUserForm.dialog = true;
        this.editUserForm.user = user;
        this.editUserForm.email = user.email;
        this.editUserForm.roles = user.roles.map((r) => r.id);
      }
    },
    stopEditingUser: function() {
      this.editUserForm = {
        dialog: false,
        user: null,
        email: null,
        roles: null,
        error: false,
        errors: {},
        loading: false,
      };
    },
    editUser: function() {
      const userId = this.editUserForm.user.id;
      const email = this.editUserForm.email;
      const roles = this.editUserForm.roles;
      const appId = this.$route.params.id;
      this.editUserForm.loading = true;
      this.editUserForm.error = false;
      this.editUserForm.errors = {};

      this.$store
        .dispatch("users/editUser", { appId, userId, email, roles })
        .then(() => {
          this.editUserForm = {
            dialog: false,
            user: null,
            email: null,
            roles: null,
            error: false,
            errors: {},
            loading: false,
          };
        })
        .catch((err) => {
          this.editUserForm.error = true;
          this.editUserForm.errors = err.response.data.errors;
          this.editUserForm.loading = false;
        });
    },
    beginEditingUserRolePermission: function(user) {
      if (user.status === 1) {
        this.editUserRolesPermission.dialog = true;
        this.editUserRolesPermission.user = user;
        this.editUserRolesPermission.roles = user.roles.map((r) => r.id);
        this.editUserRolesPermission.firstName = user.first_name;
      }
    },
    stopEditingUserRolePermission: function() {
      this.editUserRolesPermission = {
        dialog: false,
        user: null,
        firstName: null,
        roles: null,
        error: false,
        errors: {},
        loading: false,
      };
    },
    updateUserStatus: function(user, statusId) {
      this.$refs.updateUserStatusWarning.updateUserStatus(user, statusId);
    },
    getUserStatusColor: function(user) {
      const statusId =
        user.pivot.status === 1
          ? user.status === 1
            ? 1
            : 4
          : user.pivot.status;
      const status = this.userStatuses.find((s) => s.value === statusId);

      return status.color;
    },
    getUserStatusLabel: function(user) {
      const statusId =
        user.pivot.status === 1
          ? user.status === 1
            ? 1
            : 4
          : user.pivot.status;
      const status = this.userStatuses.find((s) => s.value === statusId);

      return status.label;
    },
    getAvatar: function(avatarUrl) {
      return `${process.env.VUE_APP_ACCOUNTS_URL}/storage/${avatarUrl}`;
    },
    resendSetupEmail: function(userId) {
      const appId = this.$route.params.id;
      this.userLoading = userId;

      this.$store
        .dispatch("users/resentSetupEmail", { appId, userId })
        .then(() => {
          this.userLoading = null;
          this.snackbar.text = "Setup Email Successfully Resent";
          this.snackbar.open = true;
        })
        .catch(() => {
          this.userLoading = null;
          this.snackbar.text =
            "Error Occurred when Attempting to Resent Setup Email";
          this.snackbar.open = true;
        });
    },
    hasPermission: function(permission) {
      return Utils.hasPermission(permission, this.appId);
    },
  },
};
</script>
